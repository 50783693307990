/* html,
body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
} */

.flex-section {
  /* flex-grow: 1; */
  /* display: flex; */
  /* flex-direction: row; */
  padding-top: 1vmax;
  /* min-height: 0; */
}

.flex-col-scroll-pic {
  /* flex-grow: 1; */
  /* overflow: auto; */
  max-height: 35vh;
}

@media (max-width:1023px) { 
  .flex-col-scroll-assess {
  /* flex-grow: 1; */
  overflow: auto;
  max-height: 55vh;  
  /* height: calc(100% - 100px); */  
  }
}

@media (min-width:1024px) { 
  .flex-col-scroll-assess {
  /* flex-grow: 1; */
  overflow: auto;
  max-height: 81vh;  
  /* height: calc(100% - 100px); */  
  }
}


/* .flex-no-shrink {
  flex-shrink: 0;
} */
